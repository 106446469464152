<template>
  <div>
  <div class="search-vessel-suffix-hidden" v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-1':isMobileScreen}">
    <el-form-item label="Search Vessel" v-if="!isVesselTBC" >
      <el-select
        :filter-method=" (val)=>$emit('handleSearchMpaReceivingVessels',val) "
        filterable
        popper-class="custom-ship-selector"
        v-model="searchKey"
        style="width:100%;"
        :disabled="disabled"
        @visible-change="$emit('searchVesselvisibleChange',$event) "
        @change="$emit('handleSelectVessel',$event) "
        placeholder="Select">
        <!-- currentView === ORDER_VIEW.VIEW_ORDER ||  -->
        <span style="display: inline-block; width: 200px; font-size: 14px; font-weight: 500; margin-left: 20px; margin-bottom: 10px;">Name</span>
        <span style="display: inline-block; width: 100px; font-size: 14px; font-weight: 500;">IMO</span>
        <span style="display: inline-block; font-size: 14px; font-weight: 500;">Licence</span>
        <el-option
          v-for="item in searchedVesselList"
          :key="item.id"
          :label="item.shipName"
          :value="item.id">
            <span style="display: inline-block; width: 200px; font-size: 12px;">{{item.shipName}} </span>
            <span style="display: inline-block; width: 100px; font-size: 12px;">{{item.imo}}</span>
            <span style="display: inline-block; font-size: 12px;">{{item.licenceNo}}</span>
        </el-option>
      </el-select>
    </el-form-item>
  </div>
  <div v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-1':isMobileScreen}">
    <el-form-item label="Vessel Name"  prop="vesselName" :rules="!isVesselTBC ?rules.reqRule:rules.notReqRule">
      <!-- currentView === ORDER_VIEW.VIEW_ORDER || isVesselTBC || -->
      <el-input v-model="orderForm.vesselName" :disabled="disabled"/>
    </el-form-item>
    <el-form-item label="IMO No." prop="vesselImoNo" :rules="rules.vesselImoNo">
      <el-input v-model="orderForm.vesselImoNo" :disabled="disabled"/>
    </el-form-item>

    <el-form-item label="License No."  prop="vesselLicenseNo" :rules="LICENSE_NUMBKER_RULE">
      <el-input v-model="orderForm.vesselLicenseNo" :disabled="disabled"/>
    </el-form-item>
  </div>
</div>

</template>

<script>
import {mapState} from 'vuex';
import { ORDER_VIEW ,LICENSE_NUMBKER_RULE,} from '@/constants.js'
export default {
  name: 'SearchAndCreateVesselBlock',
  props:{
    disabled:Boolean,
    isVesselTBC: Boolean,
    currentView: String,
    searchedVesselList: Array,
    orderForm: Object,
    rules: Object,
  },
  computed: {
    ...mapState([
      'isMobileScreen',

    ]),
  },
  data () {
    return {
      ORDER_VIEW,
      LICENSE_NUMBKER_RULE,
      searchKey: this.$props?.orderForm?.vesselName??''
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
